import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IntegrationPlanDataSource, IntegrationPlan } from 'app/integration-plan/integration-plan';
import { PaginatorOptions, ListHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import * as _ from 'lodash';
import {
  SetIntegrationPlanFilter,
  integrationPlanActions,
} from 'app/integration-plan/actions/integration-plan.actions';
import { integrationPlanSelectors } from 'app/integration-plan/reducers/integration-plan.reducer';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-integration-plan-popup',
  templateUrl: './integration-plan-popup-form.component.html',
  styleUrls: ['./integration-plan-popup-form.component.scss']
})
export class IntegrationPlanPopupFormComponent implements OnInit {

  dataSourceIntegrationPlan$: Observable<IntegrationPlanDataSource>;
  paginatorOpsIntegrationPlan$: Observable<PaginatorOptions>;
  sortByIntegrationPlan$: Observable<string>;
  displayedColumnsThp: string[] = ['thvbID', 'erstellDatum', 'actions'];
  filter: any;
  currentSearchEvent: any;
  sortByIntegrationPlan = {
    active: 'thvbID',
    direction: 'asc',
  };

  constructor(
    public dialogRef: MatDialogRef<IntegrationPlanPopupFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private list: ListHelperService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params
    .subscribe(params => {
      this.filter = params;
      this.store.dispatch(new SetIntegrationPlanFilter(_.omit(params, ['id', 'filter'])));
      this.store.dispatch(integrationPlanActions.filter());
    });

    this.dataSourceIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectList);
    this.paginatorOpsIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectPaginatorOptions);
    this.sortByIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectListSettings).pipe(
      map(settings => JSON.parse(decodeURIComponent(settings.sort)))
    );

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });
  }

  search(evt: any, enterKeyPressed: boolean): void {
    this.currentSearchEvent = evt;
    if (enterKeyPressed) {
      evt.preventDefault();
    } else if (evt.target.value.length > 0 && evt.target.value.length < 3) {
      return;
    }
    this.store.dispatch(new SetIntegrationPlanFilter({
      id: (evt && evt.target && evt.target.value) ? evt.target.value : '',
    }));
    this.store.dispatch(integrationPlanActions.filter());
    this.dataSourceIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectList);
  }

  toggleSort(evt: any): void {
    this.sortByIntegrationPlan = evt;
    this.reinitDataSource({ sort: encodeURIComponent(JSON.stringify(this.sortByIntegrationPlan)) });
  }

  onPaginateChange(evt: any): void {
    const newParams = this.list.encodeStateForUrl({
      size: evt.pageSize ? evt.pageSize : {},
      page: evt.pageIndex ? evt.pageIndex : {},
      sort: encodeURIComponent(JSON.stringify(this.sortByIntegrationPlan))
    });
    this.reinitDataSource(newParams);
  }

  reinitDataSource(newParams: any) {
    this.store.dispatch(new SetIntegrationPlanFilter(_.omit(newParams, ['id'])));
    this.store.dispatch(integrationPlanActions.filter());
    this.dataSourceIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectList);
    this.paginatorOpsIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectPaginatorOptions);
  }

  selectIntegrationPlan(entity: IntegrationPlan) {
    const newEntity = _.cloneDeep(entity);
    newEntity.antraege.push({ id: this.data.antragsId });
    this.closeDialog(newEntity);
  }

  closeDialog(resData: any): void {
    resData ? this.dialogRef.close(resData) : this.dialogRef.close();
  }
}
