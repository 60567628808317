import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { FormArrayHelperService } from 'app/shared/formArrayHelper.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from 'app/reducers';
import { Store } from '@ngrx/store';
import { tap, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { integrationPlanActions, DeleteIntegrationPlan, SaveIntegrationPlan } from 'app/integration-plan/actions/integration-plan.actions';
import { Observable, Subscription } from 'rxjs';
import { IntegrationPlan, TeilhabeplanArten, AnpassungFormGroup, AntragFormGroup } from 'app/integration-plan/integration-plan';
import { integrationPlanSelectors } from 'app/integration-plan/reducers/integration-plan.reducer';
import { DialogService } from 'app/dialog/dialog.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { InfoTexts } from 'app/request/request';
import { DownloadFile } from 'app/importer/actions/importer.actions';
import { selectDownloading } from 'app/importer/reducers/importer.reducer';
import { ClearIntegrationPlan } from 'app/request/actions/request.actions';

@Component({
  selector: 'app-integration-plan-inner',
  templateUrl: './integration-plan-inner-form.component.html',
  styleUrls: ['./integration-plan-inner-form.component.scss']
})
export class IntegrationPlanInnerFormComponent implements OnInit, OnDestroy {

  @Input() data: any;
  @Input() integrationPlanId: string;
  @Input() dialogRef: any;
  downloading$: Observable<boolean>;
  isInPopup: boolean;
  content: string;
  form: FormGroup;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['nr', 'datum', 'actions'];
  integrationPlan$: Observable<IntegrationPlan>;
  teilhabeplanArten = TeilhabeplanArten;
  object = Object;
  filter: any;
  curIntegrationPlanObject: any;
  anpassungFormGroup = AnpassungFormGroup;
  antragFormGroup = AntragFormGroup;
  subscription = new Subscription();
  infoTexts = InfoTexts;
  currentInfoText = '';
  returnUrl = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<State>,
    private router: Router,
    private dialog: DialogService,
    public formHelper: FormArrayHelperService,
  ) { }

  ngOnInit() {
    this.isInPopup = !!this.data;
    this.form = this.fb.group({
      thvbID: {
        value: this.generateThvbID(),
        disabled: true
      },
      erstellDatum: ['', Validators.required],
      art: ['', Validators.required],
      endeDatum: '',
      kommentar: ['', Validators.maxLength(255)],
      antraege: this.fb.array([]),
      anpassungen: this.fb.array([]),
    });

    if (this.integrationPlanId) {
      this.store.dispatch(integrationPlanActions.get(this.integrationPlanId));
      this.store.dispatch(integrationPlanActions.set({}));
    } else {
      this.route.params
        .pipe(
          tap(params => this.filter = params),
          filter(params => params.id),
          map(params => params.id),
          distinctUntilChanged()
      ).subscribe(id => this.store.dispatch(integrationPlanActions.get(id)));

      this.route.params
        .pipe(
          filter(params => !params.id),
          distinctUntilChanged()
      ).subscribe(() => this.store.dispatch(integrationPlanActions.set({})));
    }

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl;
    });

    this.integrationPlan$ = this.store.select(integrationPlanSelectors.selectCurrent);

    this.integrationPlan$.pipe(
      filter(data => !!data))
      .subscribe(data => {
        this.form.reset({ thvbID: this.generateThvbID(), ...this.form.value });
        this.formHelper.initFormArrayValues(this.antraege, data.antraege, this.antragFormGroup);
        this.formHelper.initFormArrayValues(this.anpassungen, data.anpassungen, this.anpassungFormGroup);
        this.form.patchValue(data);
        this.curIntegrationPlanObject = _.cloneDeep(data);
        this.dataSource = new MatTableDataSource<IntegrationPlan>(this.anpassungen.value);
        if (data.anpassungen) {
          this.sortFieldsByDate();
        }
    });

    this.subscription.add(
      this.form.valueChanges.subscribe(() => this.markFormGroupTouched(this.form))
    );

    if (this.isInPopup) {
      this.dialogRef.backdropClick().subscribe(() => {
        this.closePopupIfPristine();
      });
    }

    this.downloading$ = this.store.select(selectDownloading);
  }

  generateThvbID(): string {
    return (this.isInPopup && this.data.thpButtonPressed) ? this.data.antragsThvbId + '_THP' : '';
  }

  download(id: string, fileName: string): void {
    this.store.dispatch(new DownloadFile(id, 'teilhabeplan', fileName));
  }

  markFormGroupTouched(group: FormGroup) {
    (<any>Object).values(group.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Blendet Hinweise im Formular ein
   * @param evt Enthält ggf. formControlName, der dann als key für das Texte-Enum verwendet wird
   * @param controlName Manueller key für das Texte-Enum, sofern kein formControlName vorhanden ist
   */
  onFocus(evt: any, controlName?: string) {
    this.currentInfoText = this.infoTexts.teilhabeplan[controlName ? controlName : evt.target.id];
  }

  onBlur() {
    this.currentInfoText = '';
  }

  onDelete() {
    this.dialog
      .confirm('Teilhabeplan löschen', 'Sind Sie sicher?')
      .pipe(filter(response => !!response))
      .subscribe(() => {
        this.store.dispatch(new DeleteIntegrationPlan(this.curIntegrationPlanObject.id, !this.isInPopup));
        if (!!this.dialogRef) {
          this.dialogRef.close({ reload: false });
        }
        this.store.dispatch(new ClearIntegrationPlan());
      });
  }

  closePopupIfPristine() {
    if (this.form.pristine) {
      this.dialogRef.close();
    } else if (window.confirm('Sind Sie sicher, dass Sie das Formular verlassen möchten? Ungesicherte Änderungen gehen dabei verloren.')) {
      this.dialogRef.close();
    }
  }

  onSave() {
    if (this.antraege.controls.length === 0 && this.data && this.data.antragsId) {
      this.formHelper.addNewEntry(this.antraege, { id: this.data.antragsId });
    }
    this.store.dispatch(new SaveIntegrationPlan({ ...this.curIntegrationPlanObject, ...this.form.getRawValue() }, this.dialogRef));
  }

  addToFormArray(array: FormArray, requiredFields?: any) {
    this.formHelper.addNewEntry(array, _.cloneDeep(this.anpassungFormGroup), requiredFields ? requiredFields : {});
    this.recalculateIndex(array);
  }

  deleteFrom(array: FormArray, index: number): void {
    array.removeAt(index);
    this.recalculateIndex(array);
  }

  /*
    Da die Reihenfolge einiger Formularelemente wichtig sind, muss der Index
    beim Anlegen und Löschen von FormArray-Inhalten neu berechnet werden.
  */
  recalculateIndex(array: FormArray): void {
    array.controls.forEach((group, index) => {
      group.patchValue({
        nr: index + 1
      });
    });
  }

  sortFieldsByDate() {
    const sortedArr = this.anpassungen.controls.sort((a: FormGroup, b: FormGroup) => {
      return new Date(a.controls.datum.value).getTime() - new Date(b.controls.datum.value).getTime();
    });
    this.form.patchValue({ anpassung: sortedArr });
    this.recalculateIndex(this.anpassungen);
  }

  anpassungsDatumCondition(): boolean {
    return this.anpassungen.length > 0 ? !this.getLastEntryValue(this.anpassungen, 'datum') : false;
  }

  navigateBack() {
    this.router.navigateByUrl(this.returnUrl);
  }

  hasError(formControl: FormControl, errorType: string): boolean {
    return formControl.hasError(errorType);
  }

  /**
   * Gibt das Value eines gewünschten FormControls aus der letzten FormGroup eines FormArrays wieder
   */
  getLastEntryValue(array: FormArray, control: string) {
    return array.length > 0 ? (<FormGroup>array.controls[array.length - 1]).controls[control].value : undefined;
  }

  /**
   * Gibt das am weitesten in der Zukunft liegende Anpassungsdatum zurück
   */
  getMaxAnpassungsDatum(): moment.Moment {
    const moments = this.anpassungen.value.map(anpassung => moment(anpassung.datum));
    return moment.max(moments);
  }

  get antraege(): FormArray {
    return this.form.get('antraege') as FormArray;
  }

  get anpassungen(): FormArray {
    return this.form.get('anpassungen') as FormArray;
  }
}
