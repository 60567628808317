<mat-card class="title-card">
  <mat-card-header>
    <h1>Antrag erfassen</h1>
  </mat-card-header>
</mat-card>

<form [formGroup]="form">
  <mat-accordion
    togglePosition="before"
    displayMode="flat"
    multi="true"
  >
    <mat-expansion-panel
      hideToggle
      expanded="true"
      disabled="true"
    >
      <mat-expansion-panel-header class="keep-open">
        <mat-panel-title>
          <h2 class="form-title">Informationen zum Träger</h2>
          <p class="form-hint">Pflichtfelder sind mit einem * markiert.</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [hidden]="singleTraeger">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex="33%" class="form-hint-area"></div>
          <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.informationenZumTraeger && (traeger$ | async).length > 1">
            <mat-form-field appearance="fill">
              <mat-select
                required
                placeholder="Träger-ID"
                id="traeger"
                formControlName="traeger"
                [value]="form.get('traeger').value"
                [compareWith]="matSelectHelper.compareById"
              >
                <mat-option
                  *ngFor="let traeger of traeger$ | async"
                  [value]="traeger"
                  [ngClass]="traeger.eigeneID ? 'bold' : ''"
                >
                  {{ traeger.traegerID + (traeger.eigeneID ? ' (Eigene ID)' : '') }}
                </mat-option>
              </mat-select>
              <mat-label>Träger-ID</mat-label>
              <mat-error>Es muss eine Träger-ID ausgewählt werden.</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="formToggles.informationenZumTraeger && (traeger$ | async).length === 0" class="error">
            Es sind noch keine Träger hinterlegt!
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      hideToggle
      expanded="true"
      disabled="true"
    >
      <mat-expansion-panel-header class="keep-open">
        <mat-panel-title>
          <h2 class="form-title">Antrag</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <div class="form-info-box" *ngIf="currentInfoText !== '' && currentInfoSection === 'antrag'" [innerHTML]="currentInfoText"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.antrag">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
            >
              <input matInput
                required
                (focus)="onFocus($event, 'antrag')"
                maxlength="25"
                id="thvbID"
                formControlName="thvbID"
                placeholder="Antrags-ID"
              >
              <mat-label>Antrags-ID</mat-label>
              <mat-error>Es muss eine Antrags-ID eingegeben werden.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput required
                (focus)="onFocus($event, 'antrag')"
                [matDatepicker]="erstellDatumPicker"
                [min]="minDate"
                [appDisableControl]="!!filter.id"
                id="erstellDatum"
                formControlName="erstellDatum"
                placeholder="TT.MM.JJJJ"
              >
              <mat-label>Antragseingang am</mat-label>
              <mat-error>Es muss ein Datum für den Antragseingang eingetragen werden.</mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="form.controls.erstellDatum.disabled"
                (click)="form.controls.erstellDatum.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="erstellDatumPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #erstellDatumPicker></mat-datepicker>
            </mat-form-field>
          </div>

          <mat-form-field appearance="fill">
            <input matInput
              [required]="form.getRawValue().weiterleitung === 'NACH_14_1'"
              [matDatepicker]="datumFeststellungZustaendigkeitPicker"
              [min]="this.form.getRawValue().erstellDatum"
              [appDisableControl]="form.getRawValue().weiterleitung === 'NACH_14_1'"
              (focus)="onFocus($event, 'antrag')"
              placeholder="TT.MM.JJJJ"
              id="datumFeststellungZustaendigkeit"
              formControlName="datumFeststellungZustaendigkeit"
            >
            <mat-label>Zuständigkeit festgestellt am</mat-label>
            <mat-error *ngIf="hasError(form.controls.datumFeststellungZustaendigkeit, 'matDatepickerMin')">
              Das Datum der Feststellung der Zuständigkeit darf nicht vor dem Antragseingang liegen.
            </mat-error>
            <mat-error *ngIf="hasError(form.controls.datumFeststellungZustaendigkeit, 'required')">
              Es muss ein Datum für die Feststellung der Zuständigkeit eingetragen werden.
            </mat-error>
            <button mat-icon-button matSuffix
              class="delete-date"
              matTooltip="Formularfeld leeren"
              [disabled]="form.controls.datumFeststellungZustaendigkeit.disabled"
              (click)="form.controls.datumFeststellungZustaendigkeit.reset()"
            ><i class="material-icons">clear</i></button>
            <mat-datepicker-toggle matSuffix [for]="datumFeststellungZustaendigkeitPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
            <mat-datepicker #datumFeststellungZustaendigkeitPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <textarea matInput
              matTextareaAutosize
              matAutosizeMinRows="3"
              placeholder="Kommentar"
              id="kommentar"
              formControlName="kommentar"
            ></textarea>
            <mat-label>Kommentar</mat-label>
            <mat-hint align="end">{{ form.getRawValue().kommentar.length || 0 }} / 255</mat-hint>
            <mat-error *ngIf="form.controls.kommentar.hasError('maxlength')">
              Der Kommentar darf aus maximal 255 Zeichen bestehen.
            </mat-error>
          </mat-form-field>

          <div class="form-spacer-small"></div>
          <h2>Beantragte Leistungsgruppen</h2>
          <mat-error *ngIf="validators.antrag.atLeastOneLeistungsgruppeSelected()">
            Es muss mindestens eine Leistungsgruppe ausgewählt werden
          </mat-error>
          <span
            (mouseenter)="onFocus($event, 'antrag', 'leistungen')"
            (focusin)="onFocus($event, 'antrag', 'leistungen')"
          >
            <mat-checkbox
              class="check-block"
              color="primary"
              formControlName="leistungenMedizinischeReha"
              [appDisableControl]="
                form.getRawValue().leistungenMedizinischeReha
                && (entscheidung.value.datumEntscheidungLMR
                  || entscheidung.value.erledigungsartLMR
                  || entscheidung.value.datumEntscheidungGesamtantrag
                )
                || entscheidung.value.erledigungsartGesamtantrag
              "
            >
              Leistungen zur medizinischen Rehabilitation (LMR) <span class="form-info section-margin">§ 5, S. 1 (SGB IX)</span>
            </mat-checkbox>
            <mat-checkbox
              class="check-block"
              color="primary"
              formControlName="leistungenTeilhabeArbeitsleben"
              [appDisableControl]="
                form.getRawValue().leistungenTeilhabeArbeitsleben
                && (entscheidung.value.datumEntscheidungLTA
                  || entscheidung.value.erledigungsartLTA
                  || entscheidung.value.datumEntscheidungGesamtantrag
                )
                || entscheidung.value.erledigungsartGesamtantrag
                || form.getRawValue().datumLTAMassnahmeEnde
              "
              (change)="form.get('beschaeftigungNachLeistungsende').reset();resetBeruflicheTeilhabe();formToggles.teilhabe=false"
            >
              Leistungen zur Teilhabe am Arbeitsleben (LTA) <span class="form-info section-margin">§ 5, S. 2 (SGB IX)</span>
            </mat-checkbox>
            <mat-checkbox
              class="check-block"
              color="primary"
              formControlName="leistungenTeilhabeBildung"
              [appDisableControl]="
                form.getRawValue().leistungenTeilhabeBildung
                && (entscheidung.value.datumEntscheidungLTB
                  || entscheidung.value.erledigungsartLTB
                  || entscheidung.value.datumEntscheidungGesamtantrag
                )
                || entscheidung.value.erledigungsartGesamtantrag
              "
            >
              Leistungen zur Teilhabe an Bildung (LTB) <span class="form-info section-margin">§ 5, S. 4 (SGB IX)</span>
            </mat-checkbox>
            <mat-checkbox
              class="check-block"
              color="primary"
              formControlName="leistungenSozialeTeilhabe"
              [appDisableControl]="
                form.getRawValue().leistungenSozialeTeilhabe
                && (entscheidung.value.datumEntscheidungLST
                  || entscheidung.value.erledigungsartLST
                  || entscheidung.value.datumEntscheidungGesamtantrag
                )
                || entscheidung.value.erledigungsartGesamtantrag
              "
            >
              Leistungen zur sozialen Teilhabe (LST) <span class="form-info section-margin">§ 5, S. 5 (SGB IX)</span>
            </mat-checkbox>
          </span>

          <div class="form-spacer-small"></div>
          <mat-divider class="form-spacer-small" [inset]="true"></mat-divider>

          <mat-form-field appearance="fill">
            <mat-select
              [disabled]="validators.antrag.disableAntragWeitergeleitet()"
              id="weiterleitung"
              formControlName="weiterleitung"
              (selectionChange)="onChangeWeiterleitung($event)"
            >
              <mat-option value="KEINE">Nein</mat-option>
              <mat-option
                value="NACH_14_1"
                (mouseenter)="onFocus($event, 'antrag', 'antragWeitergeleitet')"
                (focusin)="onFocus($event, 'antrag', 'antragWeitergeleitet')"
              >
                Antrag weitergeleitet nach § 14 (1) S.2
              </mat-option>
              <mat-option
                value="NACH_14_3"
                (mouseenter)="onFocus($event, 'antrag', 'antragWeitergeleitetP14A3')"
                (focusin)="onFocus($event, 'antrag', 'antragWeitergeleitetP14A3')"
              >
                Antrag weitergeleitet nach § 14 (3)
              </mat-option>
            </mat-select>
            <mat-label>Antrag weitergeleitet</mat-label>
          </mat-form-field>

          <div class="form-spacer-small"></div>

          <mat-form-field appearance="fill">
            <mat-select
              [required]="!!form.getRawValue().datumFeststellungZustaendigkeit && form.getRawValue().weiterleitung === 'KEINE'"
              placeholder="Persönliches Budget beantragt"
              id="budgetBeantragt"
              formControlName="budgetBeantragt"
              (focus)="onFocus($event, 'antrag')"
            >
              <mat-option *ngFor="let antwort of object.keys(antwortBudgetEnum)" [value]="antwort">{{ antwortBudgetEnum[antwort] }}</mat-option>
            </mat-select>
            <mat-label>Persönliches Budget beantragt</mat-label>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.gutachten"
      (opened)="formToggles.gutachten = true"
      (closed)="formToggles.gutachten = false"
      [disabled]="!form.getRawValue().datumFeststellungZustaendigkeit || form.getRawValue().weiterleitung === 'NACH_14_1' || form.getRawValue().weiterleitung === 'NACH_14_3'"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Gutachten</h2>
          <p class="form-hint">§ 17 SGB IX</p>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetGutachten)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="currentInfoText !== '' && currentInfoSection === 'gutachten'" [innerHTML]="currentInfoText"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.gutachten">
          <div class="form-container" formArrayName="gutachten">
            <div *ngIf="form.getRawValue()?.gutachten?.length > 0">
              <div *ngFor="let item of gutachten.controls; let i = index">
                <div [formGroupName]="i">
                  <mat-card class="inner-card" [matBadge]="item.value.nr" matBadgePosition="before" matBadgeColor="primary">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                      <mat-form-field appearance="fill" ngClass="small no-vertical-margin" ngClass.xs="">
                        <input matInput
                          required
                          matTooltip="Datum auswählen"
                          [matDatepicker]="datumAuftragPicker"
                          [min]="form.getRawValue().erstellDatum"
                          [max]="form.getRawValue().entscheidung.datumEntscheidungGesamtantrag"
                          placeholder="TT.MM.JJJJ"
                          (focus)="onFocus($event, 'gutachten')"
                          id="datumAuftrag"
                          formControlName="datumAuftrag"
                        >
                        <mat-label>Auftrag des Gutachtens am</mat-label>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumAuftrag, 'matDatepickerMin')">
                          Das Datum des Gutachtenauftrags darf nicht vor dem Antragseingang liegen.
                        </mat-error>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumAuftrag, 'matDatepickerMax')">
                          Das Gutachten darf nicht nach dem Entscheidungsdatum des Gesamtantrags beauftragt worden sein.
                        </mat-error>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumAuftrag, 'required')">
                          Es muss ein Datum eingegeben werden, an dem das Gutachten in Auftrag gegeben wurde.
                        </mat-error>
                        <button mat-icon-button matSuffix
                          class="delete-date"
                          matTooltip="Formularfeld leeren"
                          [disabled]="gutachten.controls[i].controls.datumAuftrag.disabled"
                          (click)="gutachten.controls[i].controls.datumAuftrag.reset()"
                        ><i class="material-icons">clear</i></button>
                        <mat-datepicker-toggle matSuffix [for]="datumAuftragPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
                        <mat-datepicker #datumAuftragPicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill" ngClass="small no-vertical-margin" ngClass.xs="">
                        <input matInput
                          [matDatepicker]="datumVorliegenPicker"
                          [min]="form.getRawValue().erstellDatum"
                          [max]="gutachten?.length == (i+1) ? entscheidung.controls.datumEntscheidungGesamtantrag.value : undefined"
                          placeholder="TT.MM.JJJJ"
                          (focus)="onFocus($event, 'gutachten')"
                          id="datumVorliegen"
                          formControlName="datumVorliegen"
                        >
                        <mat-label>Vorliegen des Gutachtens am</mat-label>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumVorliegen, 'matDatepickerMin')">
                          Das Datum des Vorliegens des Gutachtens darf nicht vor dem Antragseingang liegen.
                        </mat-error>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumVorliegen, 'matDatepickerMax')">
                          Das letzte Gutachten muss vor dem Entscheidungsdatum des Gesamtantrags vorliegen.
                        </mat-error>
                        <mat-error *ngIf="hasError(gutachten.controls[i]?.controls?.datumVorliegen, 'required')">
                          Es muss ein Datum eingegeben werden, an dem das Gutachten vorlag.
                        </mat-error>
                        <button mat-icon-button matSuffix
                          class="delete-date"
                          matTooltip="Formularfeld leeren"
                          [disabled]="gutachten.controls[i].controls.datumVorliegen.disabled"
                          (click)="gutachten.controls[i].controls.datumVorliegen.reset()"
                        ><i class="material-icons">clear</i></button>
                        <mat-datepicker-toggle matSuffix [for]="datumVorliegenPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
                        <mat-datepicker #datumVorliegenPicker></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <button mat-icon-button
                      (click)="deleteFrom(gutachten, i)"
                      color="warn"
                      class="delete inner-form-delete"
                    >
                      <i class="material-icons">delete_forever</i>
                    </button>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                      <mat-checkbox
                        class="check-block small"
                        color="primary"
                        formControlName="leistungenMedizinischeReha"
                        [disabled]="!form.getRawValue().leistungenMedizinischeReha"
                        [checked]="form.getRawValue().leistungenMedizinischeReha ? gutachten.value[i].leistungenMedizinischeReha : false"
                      >
                        LMR <span class="form-info section-margin">§ 5, S. 1 (SGB IX)</span>
                      </mat-checkbox>
                      <mat-checkbox
                        class="check-block small"
                        color="primary"
                        formControlName="leistungenTeilhabeArbeitsleben"
                        [disabled]="!form.getRawValue().leistungenTeilhabeArbeitsleben"
                        [checked]="form.getRawValue().leistungenTeilhabeArbeitsleben ? gutachten.value[i].leistungenTeilhabeArbeitsleben : false"
                      >
                        LTA <span class="form-info section-margin">§ 5, S. 2 (SGB IX)</span>
                      </mat-checkbox>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                      <mat-checkbox
                        class="check-block small"
                        color="primary"
                        formControlName="leistungenTeilhabeBildung"
                        [disabled]="!form.getRawValue().leistungenTeilhabeBildung"
                        [checked]="form.getRawValue().leistungenTeilhabeBildung ? gutachten.value[i].leistungenTeilhabeBildung : false"
                      >
                        LTB <span class="form-info section-margin">§ 5, S. 4 (SGB IX)</span>
                      </mat-checkbox>
                      <mat-checkbox
                        class="check-block small"
                        color="primary"
                        formControlName="leistungenSozialeTeilhabe"
                        [disabled]="!form.getRawValue().leistungenSozialeTeilhabe"
                        [checked]="form.getRawValue().leistungenSozialeTeilhabe ? gutachten.value[i].leistungenSozialeTeilhabe : false"
                      >
                        LST <span class="form-info section-margin">§ 5, S. 5 (SGB IX)</span>
                      </mat-checkbox>
                    </div>
                    <mat-error *ngIf="validators.gutachten.atLeastOneLeistungsgruppeSelected(i)">
                      Es muss mindestens eine Leistungsgruppe ausgewählt werden
                    </mat-error>
                  </mat-card>
                  <div class="form-spacer-small"></div>
                </div>
              </div>
            </div>
            <button mat-stroked-button
              class="no-margin"
              color="primary"
              [disabled]="validators.gutachten.disableButtonAddGutachten()"
              (click)="addToFormArray(gutachten, gutachtenFormGroup, 'Gutachten', true, { datumAuftrag: requiredValidator })"
            >
              <i class="material-icons">add</i> Gutachten hinzufügen
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.erledigungBewilligung"
      (opened)="formToggles.erledigungBewilligung = true"
      (closed)="formToggles.erledigungBewilligung = false"
      [disabled]="
        !form.getRawValue().datumFeststellungZustaendigkeit
        || form.getRawValue().weiterleitung === 'NACH_14_1'
        || form.getRawValue().weiterleitung === 'NACH_14_3'
      "
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Entscheidung</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            [disabled]="
              !form.getRawValue().datumFeststellungZustaendigkeit
              || form.getRawValue().weiterleitung === 'NACH_14_1'
              || form.getRawValue().weiterleitung === 'NACH_14_3'
            "
            (click)="confirmDelete(resetEntscheidung)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="currentInfoText !== '' && currentInfoSection === 'erledigung'" [innerHTML]="currentInfoText"></div>
        </div>
        <div
          fxFlex="66%"
          fxLayout="column"
          fxLayoutGap="20px"
          *ngIf="formToggles.erledigungBewilligung"
          formGroupName="entscheidung"
        >
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                [required]="validators.entscheidung.requireDatumErledigung('erledigungsartLMR')"
                [matDatepicker]="datumEntscheidungLMRPicker"
                [min]="form.getRawValue().datumFeststellungZustaendigkeit"
                placeholder="TT.MM.JJJJ"
                id="datumEntscheidungLMR"
                formControlName="datumEntscheidungLMR"
              >
              <mat-label>Entscheidungsdatum LMR (§ 5, S. 1 (SGB IX))</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLMR, 'matDatepickerMin')">
                Das Entscheidungsdatum innerhalb der Leistungen zur medizinischen Rehabilitation darf nicht vor der Feststellung der Zuständigkeit liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLMR, 'required')">
                Es muss das Entscheidungsdatum des Antrages innerhalb der Leistungen zur medizinischen Rehabilitation eingetragen werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="validators.entscheidung.disableEntscheidungen()"
                (click)="entscheidung.controls.datumEntscheidungLMR.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumEntscheidungLMRPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumEntscheidungLMRPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
              [matTooltip]="erledigungsartEnum[entscheidung.getRawValue().erledigungsartLMR]"
              matTooltipPosition="above"
            >
              <mat-select
                [required]="validators.entscheidung.requireArtErledigung('datumEntscheidungLMR')"
                placeholder="Entscheidungsart LMR"
                (focus)="onFocus($event, 'erledigung', 'erledigungsArt')"
                id="erledigungsartLMR"
                formControlName="erledigungsartLMR"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let art of getFilteredErledigungsArten('lmr')"
                  [value]="art"
                  [matTooltip]="erledigungsartLeistungEnum[art]"
                  matTooltipPosition="left"
                >
                  {{ erledigungsartEnum[art] }}
                </mat-option>
              </mat-select>
              <mat-label>Entscheidungsart LMR (§ 5, S. 1 (SGB IX))</mat-label>
              <mat-error>Es muss eine Entscheidungsart des Antrages innerhalb der Leistungen zur medizinischen Rehabilitation ausgewählt werden.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                [required]="validators.entscheidung.requireDatumErledigung('erledigungsartLTA')"
                [matDatepicker]="datumEntscheidungLTAPicker"
                [min]="form.getRawValue().datumFeststellungZustaendigkeit"
                placeholder="TT.MM.JJJJ"
                id="datumEntscheidungLTA"
                formControlName="datumEntscheidungLTA"
              >
              <mat-label>Entscheidungsdatum LTA (§ 5, S. 2 (SGB IX))</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLTA, 'matDatepickerMin')">
                Das Entscheidungsdatum innerhalb der Leistungen zur Teilhabe am Arbeitsleben darf nicht vor der Feststellung der Zuständigkeit liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLTA, 'required')">
                Es muss das Entscheidungsdatum des Antrages innerhalb der Leistungen zur Teilhabe am Arbeitsleben eingetragen werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="validators.entscheidung.disableEntscheidungen()"
                (click)="entscheidung.controls.datumEntscheidungLTA.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumEntscheidungLTAPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumEntscheidungLTAPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
              [matTooltip]="erledigungsartEnum[entscheidung.getRawValue().erledigungsartLTA]"
              matTooltipPosition="above"
            >
              <mat-select
                [required]="validators.entscheidung.requireArtErledigung('datumEntscheidungLTA')"
                placeholder="Entscheidungsart LTA"
                (focus)="onFocus($event, 'erledigung', 'erledigungsArt')"
                id="erledigungsartLTA"
                formControlName="erledigungsartLTA"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let art of getFilteredErledigungsArten('lta')"
                  [value]="art"
                  [matTooltip]="erledigungsartLeistungEnum[art]"
                  matTooltipPosition="left"
                >
                  {{ erledigungsartEnum[art] }}
                </mat-option>
              </mat-select>
              <mat-label>Entscheidungsart LTA (§ 5, S. 2 (SGB IX))</mat-label>
              <mat-error>Es muss eine Entscheidungsart des Antrages innerhalb der Leistungen zur Teilhabe am Arbeitsleben ausgewählt werden.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                [required]="validators.entscheidung.requireDatumErledigung('erledigungsartLTB')"
                [matDatepicker]="datumEntscheidungLTBPicker"
                [min]="form.getRawValue().datumFeststellungZustaendigkeit"
                placeholder="TT.MM.JJJJ"
                id="datumEntscheidungLTB"
                formControlName="datumEntscheidungLTB"
              >
              <mat-label>Entscheidungsdatum LTB (§ 5, S. 4 (SGB IX))</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLTB, 'matDatepickerMin')">
                Das Entscheidungsdatum innerhalb der Leistungen zur Teilhabe an Bildung darf nicht vor der Feststellung der Zuständigkeit liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLTB, 'required')">
                Es muss das Entscheidungsdatum des Antrages innerhalb der Leistungen zur Teilhabe an Bildung eingetragen werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="validators.entscheidung.disableEntscheidungen()"
                (click)="entscheidung.controls.datumEntscheidungLTB.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumEntscheidungLTBPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumEntscheidungLTBPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
              [matTooltip]="erledigungsartEnum[entscheidung.getRawValue().erledigungsartLTB]"
              matTooltipPosition="above"
            >
              <mat-select
                [required]="validators.entscheidung.requireArtErledigung('datumEntscheidungLTB')"
                placeholder="Entscheidungsart LTB"
                (focus)="onFocus($event, 'erledigung', 'erledigungsArt')"
                id="erledigungsartLTB"
                formControlName="erledigungsartLTB"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let art of getFilteredErledigungsArten('ltb')"
                  [value]="art"
                  [matTooltip]="erledigungsartLeistungEnum[art]"
                  matTooltipPosition="left"
                >
                  {{ erledigungsartEnum[art] }}
                </mat-option>
              </mat-select>
              <mat-label>Entscheidungsart LTB (§ 5, S. 4 (SGB IX))</mat-label>
              <mat-error>Es muss eine Entscheidungsart des Antrages innerhalb der Leistungen zur Teilhabe an Bildung ausgewählt werden.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                [required]="validators.entscheidung.requireDatumErledigung('erledigungsartLST')"
                [matDatepicker]="datumEntscheidungLSTPicker"
                [min]="form.getRawValue().datumFeststellungZustaendigkeit"
                placeholder="TT.MM.JJJJ"
                id="datumEntscheidungLST"
                formControlName="datumEntscheidungLST"
              >
              <mat-label>Entscheidungsdatum LST (§ 5, S. 5 (SGB IX))</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLST, 'matDatepickerMin')">
                Das Entscheidungsdatum innerhalb der Leistungen zur sozialen Teilhabe darf nicht vor der Feststellung der Zuständigkeit liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungLST, 'required')">
                Es muss das Entscheidungsdatum des Antrages innerhalb der Leistungen zur sozialen Teilhabe eingetragen werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="validators.entscheidung.disableEntscheidungen()"
                (click)="entscheidung.controls.datumEntscheidungLST.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumEntscheidungLSTPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumEntscheidungLSTPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
              [matTooltip]="erledigungsartEnum[entscheidung.getRawValue().erledigungsartLST]"
              matTooltipPosition="above"
            >
              <mat-select
                [required]="validators.entscheidung.requireArtErledigung('datumEntscheidungLST')"
                placeholder="Entscheidungsart LST"
                (focus)="onFocus($event, 'erledigung', 'erledigungsArt')"
                id="erledigungsartLST"
                formControlName="erledigungsartLST"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let art of getFilteredErledigungsArten('lst')"
                  [value]="art"
                  [matTooltip]="erledigungsartLeistungEnum[art]"
                  matTooltipPosition="left"
                >
                  {{ erledigungsartEnum[art] }}
                </mat-option>
              </mat-select>
              <mat-label>Entscheidungsart LST (§ 5, S. 5 (SGB IX))</mat-label>
              <mat-error>Es muss eine Entscheidungsart des Antrages innerhalb der Leistungen zur sozialen Teilhabe ausgewählt werden.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="highlighted-form-field">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                [required]="validators.entscheidung.requireDatumEntscheidungGesamtantrag()"
                [matDatepicker]="datumEntscheidungGesamtantragPicker"
                [min]="validators.entscheidung.getDatumEntscheidungGesamtantragMinDate()"
                [appDisableControl]="validators.entscheidung.disableErledigungsDatum()"
                (focus)="onFocus($event, 'erledigung', 'erledigungsDatum')"
                placeholder="TT.MM.JJJJ"
                id="datumEntscheidungGesamtantrag"
                formControlName="datumEntscheidungGesamtantrag"
              >
              <mat-label>Entscheidungsdatum des Gesamtantrages</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungGesamtantrag, 'matDatepickerMin')">
                <span *ngIf="validators.entscheidung.getDatumEntscheidungGesamtantragMinDate() === form.getRawValue().datumFeststellungZustaendigkeit; else datumEntscheidungGesamtantragMinError">
                  Das Entscheidungsdatum des Gesamtantrags darf nicht vor der Feststellung der Zuständigkeit liegen.
                </span>
                <ng-template #datumEntscheidungGesamtantragMinError>
                  Das Entscheidungsdatum des Gesamtantrags darf nicht vor dem Vorliegen des letzten Gutachtens liegen.
                </ng-template>
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumEntscheidungGesamtantrag, 'required')">
                Es muss eine Angabe zum Entscheidungsdatum des Gesamtantrags gemacht werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="entscheidung.controls.datumEntscheidungGesamtantrag.disabled"
                (click)="entscheidung.controls.datumEntscheidungGesamtantrag.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumEntscheidungGesamtantragPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumEntscheidungGesamtantragPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              ngClass="small"
              ngClass.xs=""
              [matTooltip]="erledigungsartEnum[entscheidung.getRawValue().erledigungsartGesamtantrag]"
              matTooltipPosition="above"
            >
              <mat-select
                [appDisableControl]="validators.entscheidung.disableErledigungsDatum()"
                [required]="validators.entscheidung.requireDatumEntscheidungGesamtantrag()"
                (focus)="onFocus($event, 'erledigung', 'erledigungsArt')"
                placeholder="Entscheidungsart des Gesamtantrages"
                id="erledigungsartGesamtantrag"
                formControlName="erledigungsartGesamtantrag"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let art of [getErledigungsArt()]"
                  [matTooltip]="erledigungsartEnum[art]"
                  matTooltipPosition="left"
                  [disabled]="art === weiterleitung"
                  [value]="art"
                >
                  {{ erledigungsartEnum[art] }}
                </mat-option>
              </mat-select>
              <mat-label>Entscheidungsart des Gesamtantrages</mat-label>
              <mat-error>Es muss eine Angabe zur Entscheidungsart des Gesamtantrags gemacht werden.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="full-width" ngClass.xs="">
              <input matInput
                [matDatepicker]="datumBewilligungsbescheidPicker"
                [min]="form.getRawValue().datumFeststellungZustaendigkeit"
                [appDisableControl]="validators.entscheidung.erledigungsArtSelected()"
                (focus)="onFocus($event, 'erledigung', 'erledigungsDatum')"
                placeholder="TT.MM.JJJJ"
                id="datumBewilligungsbescheid"
                formControlName="datumBewilligungsbescheid"
              >
              <mat-label>Datum des ersten Bewilligungsbescheides</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumBewilligungsbescheid, 'matDatepickerMin')">
                Das Datum des ersten Bewilligungsbescheides darf nicht vor der Zuständigkeitsfeststellung liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumBewilligungsbescheid, 'required')">
                Es muss das Datum des ersten Bewilligungsbescheides eingetragen werden.
              </mat-error>
              <button mat-icon-button matSuffix
                class="delete-date"
                matTooltip="Formularfeld leeren"
                [disabled]="entscheidung.controls.datumBewilligungsbescheid.disabled"
                (click)="entscheidung.controls.datumBewilligungsbescheid.reset()"
              ><i class="material-icons">clear</i></button>
              <mat-datepicker-toggle matSuffix [for]="datumBewilligungsbescheidPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
              <mat-datepicker #datumBewilligungsbescheidPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-spacer-small"></div>
          <mat-form-field appearance="fill" ngClass="full-width">
            <input matInput
              [matDatepicker]="datumLeistungsbeginnPicker"
              [min]="validators.entscheidung.getMinDateLeistungsbeginn()"
              (focus)="onFocus($event, 'erledigung')"
              placeholder="TT.MM.JJJJ"
              [appDisableControl]="validators.entscheidung.erledigungsArtSelected()"
              [disabled]="validators.entscheidung.erledigungsArtSelected()"
              id="datumLeistungsbeginn"
              formControlName="datumLeistungsbeginn"
            >
            <mat-label>Leistungsbeginn der ersten angetretenen Leistung</mat-label>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumLeistungsbeginn, 'matDatepickerMin')">
                Das Datum des Leistungsbeginns der ersten angetretenen Leistung darf nicht vor dem Antragseingang liegen.
              </mat-error>
              <mat-error *ngIf="hasError(entscheidung.controls?.datumLeistungsbeginn, 'required')">
                Es muss ein Leistungsbeginn der ersten angetretenen Leistung eingetragen werden.
              </mat-error>
            <button mat-icon-button matSuffix
              class="delete-date"
              matTooltip="Formularfeld leeren"
              [disabled]="entscheidung.controls.datumLeistungsbeginn.disabled"
              (click)="entscheidung.controls.datumLeistungsbeginn.reset()"
            ><i class="material-icons">clear</i></button>
            <mat-datepicker-toggle matSuffix [for]="datumLeistungsbeginnPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
            <mat-datepicker #datumLeistungsbeginnPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            ngClass="small"
            ngClass.xs=""
          >
            <mat-select
              [required]="!validators.entscheidung.disableBudgetBewilligt() && (!!entscheidung.value.erledigungsartGesamtantrag || !!entscheidung.value.datumLeistungsbeginn)"
              [disabled]="validators.entscheidung.disableBudgetBewilligt()"
              placeholder="Persönliches Budget bewilligt"
              (focus)="onFocus($event, 'erledigung')"
              id="budgetBewilligt"
              formControlName="budgetBewilligt"
            >
              <mat-option *ngFor="let antwort of object.keys(antwortBudgetEnum)" [value]="antwort">{{ antwortBudgetEnum[antwort] }}</mat-option>
            </mat-select>
            <mat-label>Persönliches Budget bewilligt</mat-label>
            <mat-error>Es muss eine Angabe dazu gemacht werden, ob ein persönliches Budget bewilligt wurde.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.teilhabeplanung"
      (opened)="formToggles.teilhabeplanung = true"
      (closed)="formToggles.teilhabeplanung = false"
      [disabled]="!form.getRawValue().datumFeststellungZustaendigkeit || form.getRawValue().weiterleitung === 'NACH_14_1' || form.getRawValue().weiterleitung === 'NACH_14_3'"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Teilhabeplanung</h2>
          <p class="form-hint">§ 19ff. SGB IX</p>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <div class="form-info-box" *ngIf="formToggles.teilhabeplanung" [innerHTML]="infoTexts.teilhabeplan.defaultAntrag"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.teilhabeplanung">
          <mat-error *ngIf="!form.getRawValue().thvbID">Bitte vergeben Sie zunächst eine Antrags-ID</mat-error>
          <div class="form-container">
            <button mat-stroked-button fxFlex
              color="primary"
              (click)="showForm(false, false)"
              *ngIf="!currentIntegrationPlan.thvbID"
            >
              <i class="material-icons">search</i> THP suchen
            </button>
            <button mat-stroked-button fxFlex
              color="primary"
              (click)="showForm(true, true)"
              *ngIf="!currentIntegrationPlan.thvbID"
            >
              <i class="material-icons">add</i> Neuen THP hinzufügen
            </button>
            <button mat-stroked-button fxFlex
              color="primary"
              (click)="showForm(true, false)"
              *ngIf="!!currentIntegrationPlan.thvbID"
            >
              <i class="material-icons">open_in_new</i> THP anzeigen
            </button>
            <button mat-stroked-button fxFlex
              color="warn"
              (click)="deleteIntegrationPlan()"
              *ngIf="currentIntegrationPlan.thvbID && (currentIntegrationPlan.thvbID !== this.form.getRawValue().thvbID + '_THP')"
            >
              <i class="material-icons">delete</i> THP entfernen
            </button>
          </div>
          <div class="form-spacer-medium"></div>
          <mat-form-field
            appearance="fill"
            *ngIf="!!currentIntegrationPlan.thvbID"
          >
            <input matInput
              placeholder="Zugehöriger THP (ID)"
              [value]="currentIntegrationPlan.thvbID"
              disabled
            >
            <mat-label>Zugehöriger THP (ID)</mat-label>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.erstattungsantraege"
      (opened)="formToggles.erstattungsantraege = true"
      (closed)="formToggles.erstattungsantraege = false"
      [disabled]="validators.antrag.weiterleitungSelected()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">
            Versand von Mitteilungen wegen langer Verfahrensdauer (§ 18 Abs. 1 SGB IX) und Erstattungsanträge bei selbstbeschafften Leistungen (§ 18 SGB IX)
          </h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetErstattungsantrag)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="currentInfoText !== '' && currentInfoSection === 'erstattung'" [innerHTML]="currentInfoText"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.erstattungsantraege">
          <mat-form-field appearance="fill">
            <mat-select
              id="anzahlVersandteMitteilungen"
              formControlName="anzahlVersandteMitteilungen"
              placeholder="Anzahl Mitteilungen nach §18 (1) SGB IX"
              (focus)="onFocus($event, 'erstattung')"
            >
              <mat-option *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]" [value]="number">{{ number }}</mat-option>
            </mat-select>
            <mat-label>Anzahl Mitteilungen nach §18 (1) SGB IX</mat-label>
            <mat-error>Es muss eine Angabe zu der Anzahl der versandten Mitteilungen nach § 18 (1) gemacht werden.</mat-error>
          </mat-form-field>

          <div formArrayName="erstattungsantrag">
            <div *ngIf="form.getRawValue()?.erstattungsantrag?.length > 0">
              <div *ngFor="let item of erstattungsantrag.controls; let i = index">
                <div [formGroupName]="i">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" [matBadge]="item.value.nr" matBadgePosition="before" matBadgeColor="primary">
                    <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
                      <input matInput
                        required
                        [matDatepicker]="datumErledigungPicker"
                        [min]="form.getRawValue().erstellDatum"
                        placeholder="TT.MM.JJJJ"
                        id="datumErledigung"
                        formControlName="datumErledigung"
                        (focus)="onFocus($event, 'erstattung')"
                      >
                      <mat-label>Erledigungsdatum Erstattungsantrag</mat-label>
                      <mat-error *ngIf="hasError(erstattungsantrag.controls[i]?.controls?.datumErledigung, 'matDatepickerMin')">
                        Das Erledigungsdatum eines Erstattungsantrags darf nicht vor dem Antragseingang liegen.
                      </mat-error>
                      <mat-error *ngIf="hasError(erstattungsantrag.controls[i]?.controls?.datumErledigung, 'required')">
                        Es muss ein Erledigungsdatum des Erstattungsantrages eingegeben werden.
                      </mat-error>
                      <button mat-icon-button matSuffix
                        class="delete-date"
                        matTooltip="Formularfeld leeren"
                        [disabled]="erstattungsantrag.controls[i].controls.datumErledigung.disabled"
                        (click)="erstattungsantrag.controls[i].controls.datumErledigung.reset()"
                      ><i class="material-icons">clear</i></button>
                      <mat-datepicker-toggle matSuffix [for]="datumErledigungPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
                      <mat-datepicker #datumErledigungPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      ngClass="small"
                      ngClass.xs=""
                      [matTooltip]="erstattungsantragArtEnum[erstattungsantrag.controls[i].value.erledigungsart]"
                      matTooltipPosition="above"
                    >
                      <mat-select
                        placeholder="Entscheidungsart Erstattungsantrag"
                        id="erledigungsart"
                        (focus)="onFocus($event, 'erstattung')"
                        formControlName="erledigungsart"
                        [required]="erstattungsantrag.value[i].datumErledigung !== ''"
                        [appDisableControl]="erstattungsantrag.value[i].datumErledigung === ''"
                      >
                        <mat-option></mat-option>
                        <mat-option
                          *ngFor="let art of object.keys(erstattungsantragArtEnum)"
                          [matTooltip]="erstattungsantragArtEnum[art]"
                          matTooltipPosition="left"
                          [value]="art"
                        >
                          {{ erstattungsantragArtEnum[art] }}
                        </mat-option>
                      </mat-select>
                      <mat-label>Entscheidungsart Erstattungsantrag</mat-label>
                      <mat-error>Es muss eine Angabe zu Entscheidungsart des Erstattungsantrages gemacht werden.</mat-error>
                    </mat-form-field>
                    <button mat-icon-button
                      (click)="deleteFrom(erstattungsantrag, i)"
                      color="warn"
                      class="delete"
                    >
                      <i class="material-icons">delete_forever</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button mat-stroked-button
              class="no-margin"
              mat-line
              color="primary"
              [disabled]="validators.erstattungsantrag.disableButtonAddErstattungsantrag()"
              (click)="addToFormArray(erstattungsantrag, defaultFormGroup, 'Erstattungsantrag', false, { datumErledigung: requiredValidator })"
            >
              <i class="material-icons">add</i> Erstattungsantrag hinzufügen
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.widersprueche"
      (opened)="formToggles.widersprueche = true"
      (closed)="formToggles.widersprueche = false"
      [disabled]="validators.antrag.weiterleitungSelected()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Widersprüche</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetWidersprueche)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="formToggles.widersprueche" [innerHTML]="infoTexts.widerspruch.defaultAntrag"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.widersprueche">
          <div formArrayName="widersprueche">
            <div *ngIf="form.getRawValue()?.widersprueche?.length > 0">
              <div *ngFor="let item of widersprueche.controls; let i = index">
                <div [formGroupName]="i">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" [matBadge]="item.value.nr" matBadgePosition="before" matBadgeColor="primary">
                    <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
                      <input matInput
                        required
                        [matDatepicker]="datumErledigungPicker"
                        [min]="form.getRawValue().erstellDatum"
                        placeholder="TT.MM.JJJJ"
                        id="datumErledigung"
                        formControlName="datumErledigung"
                      >
                      <mat-label>Erledigungsdatum</mat-label>
                      <mat-error *ngIf="hasError(widersprueche.controls[i]?.controls?.datumErledigung, 'matDatepickerMin')">
                        Das Erledigungsdatum eines Widerspruchs darf nicht vor dem Antragseingang liegen.
                      </mat-error>
                      <mat-error *ngIf="hasError(widersprueche.controls[i]?.controls?.datumErledigung, 'required')">
                        Es muss ein Erledigungsdatum eingegeben werden.
                      </mat-error>
                      <button mat-icon-button matSuffix
                        class="delete-date"
                        matTooltip="Formularfeld leeren"
                        [disabled]="widersprueche.controls[i].controls.datumErledigung.disabled"
                        (click)="widersprueche.controls[i].controls.datumErledigung.reset()"
                      ><i class="material-icons">clear</i></button>
                      <mat-datepicker-toggle matSuffix [for]="datumErledigungPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
                      <mat-datepicker #datumErledigungPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      ngClass="small"
                      ngClass.xs=""
                      [matTooltip]="widerspruchKlageEnum[widersprueche.controls[i].value.erledigungsart]"
                      matTooltipPosition="above"
                    >
                      <mat-select
                        placeholder="Entscheidungsart"
                        formControlName="erledigungsart"
                        [required]="widersprueche.value[i].datumErledigung !== ''"
                        [appDisableControl]="widersprueche.value[i].datumErledigung === ''"
                      >
                        <mat-option></mat-option>
                        <mat-option
                          *ngFor="let antwort of object.keys(widerspruchKlageEnum)"
                          [value]="antwort"
                          [matTooltip]="widerspruchKlageEnum[antwort]"
                          matTooltipPosition="left"
                        >
                          {{ widerspruchKlageEnum[antwort] }}
                        </mat-option>
                      </mat-select>
                      <mat-label>Entscheidungsart</mat-label>
                      <mat-error>Es muss eine Angabe zu Entscheidungsart des Widerspruches gemacht werden.</mat-error>
                    </mat-form-field>
                    <button mat-icon-button
                      (click)="deleteFrom(widersprueche, i)"
                      color="warn"
                      class="delete"
                    >
                      <i class="material-icons">delete_forever</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button mat-stroked-button
              class="no-margin"
              color="primary"
              [disabled]="validators.widersprueche.disableButtonAddWiderspruch()"
              (click)="addToFormArray(widersprueche, defaultFormGroup, 'Widersprüche', false, { datumErledigung: requiredValidator })"
            >
              <i class="material-icons">add</i> Widerspruch hinzufügen
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.klagen"
      (opened)="formToggles.klagen = true"
      (closed)="formToggles.klagen = false"
      [disabled]="validators.antrag.weiterleitungSelected()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Klagen</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetKlagen)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="formToggles.klagen" [innerHTML]="infoTexts.klagen.defaultAntrag"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.klagen">
          <div formArrayName="klagen">
            <div *ngIf="form.getRawValue()?.klagen?.length > 0">
              <div *ngFor="let item of klagen.controls; let i = index">
                <div [formGroupName]="i">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" [matBadge]="item.value.nr" matBadgePosition="before" matBadgeColor="primary">
                    <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
                      <input matInput
                        required
                        [matDatepicker]="klagenDatumErledigungPicker"
                        [min]="form.getRawValue().erstellDatum"
                        placeholder="TT.MM.JJJJ"
                        id="datumErledigung"
                        formControlName="datumErledigung"
                      >
                      <mat-label>Erledigungsdatum</mat-label>
                      <mat-error *ngIf="hasError(klagen.controls[i]?.controls?.datumErledigung, 'matDatepickerMin')">
                        Das Erledigungsdatum einer Klage darf nicht vor dem Antragseingang liegen.
                      </mat-error>
                      <mat-error *ngIf="hasError(klagen.controls[i]?.controls?.datumErledigung, 'required')">
                        Es muss ein Erledigungsdatum der Klage eingegeben werden.
                      </mat-error>
                      <button mat-icon-button matSuffix
                        class="delete-date"
                        matTooltip="Formularfeld leeren"
                        [disabled]="klagen.controls[i].controls.datumErledigung.disabled"
                        (click)="klagen.controls[i].controls.datumErledigung.reset()"
                      ><i class="material-icons">clear</i></button>
                      <mat-datepicker-toggle matSuffix [for]="klagenDatumErledigungPicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
                      <mat-datepicker #klagenDatumErledigungPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                      appearance="fill"
                      ngClass="small"
                      ngClass.xs=""
                      [matTooltip]="widerspruchKlageEnum[klagen.controls[i].value.erledigungsart]"
                      matTooltipPosition="above"
                    >
                      <mat-select
                        placeholder="Entscheidungsart"
                        formControlName="erledigungsart"
                        [required]="klagen.value[i].datumErledigung !== ''"
                        [appDisableControl]="klagen.value[i].datumErledigung === ''"
                      >
                        <mat-option></mat-option>
                        <mat-option
                          *ngFor="let antwort of object.keys(widerspruchKlageEnum)"
                          [value]="antwort"
                          [matTooltip]="widerspruchKlageEnum[antwort]"
                          matTooltipPosition="left"
                        >
                          {{ widerspruchKlageEnum[antwort] }}
                        </mat-option>
                      </mat-select>
                      <mat-label>Entscheidungsart</mat-label>
                      <mat-error>Es muss eine Angabe zu Entscheidungsart der Klage gemacht werden.</mat-error>
                    </mat-form-field>
                    <button mat-icon-button
                      (click)="deleteFrom(klagen, i)"
                      color="warn"
                      class="delete"
                    >
                      <i class="material-icons">delete_forever</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button mat-stroked-button
              class="no-margin"
              color="primary"
              [disabled]="validators.klagen.disableButtonAddKlagen()"
              (click)="addToFormArray(klagen, defaultFormGroup, 'Klagen', false, { datumErledigung: requiredValidator })"
            >
              <i class="material-icons">add</i> Klage hinzufügen
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.beschaeftigung"
      (opened)="formToggles.beschaeftigung = true"
      (closed)="formToggles.beschaeftigung = false"
      [disabled]="validators.antrag.weiterleitungSelected()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Erstattung zwischen Rehabilitationsträgern (§ 16 Abs. 2 Satz 2 SGB IX)</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetErstattungsverfahren)"
          >
            Eingaben entfernen
          </button>
          <div *ngIf="validators.erstattungsverfahren.mustBeUnset()" class="form-info-box warning">
            {{ infoTexts.erstattungsverfahren.nichtMoeglichWennAntragAbgelehnt }}
          </div>
          <div *ngIf="formToggles.beschaeftigung && currentInfoText !== '' && currentInfoSection === 'erstattungsverfahren' && validators.erstattungsverfahren.canBeSet()"
            class="form-info-box"
            [innerHTML]="currentInfoText"></div>
          <div class="form-spacer-medium" *ngIf="formToggles.beschaeftigung && currentInfoText === ''"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.beschaeftigung">
          <div class="form-container" formArrayName="erstattungsverfahren">
            <div *ngIf="form.getRawValue()?.erstattungsverfahren?.length > 0">
              <div *ngFor="let item of erstattungsverfahren.controls; let i = index">
                <div [formGroupName]="i">
                  <div fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutGap="10px"
                    [matBadge]="item.value.nr"
                    matBadgePosition="before"
                    matBadgeColor="primary"
                  >
                    <mat-form-field appearance="fill" ngClass="no-vertical-margin full-width" ngClass.xs="">
                      <input matInput
                        [matDatepicker]="datumVerfahrenPicker"
                        [min]="form.getRawValue().erstellDatum"
                        placeholder="TT.MM.JJJJ"
                        id="datumVerfahren"
                        formControlName="datumVerfahren"
                        (focus)="onFocus($event, 'erstattungsverfahren', 'defaultAntrag')"
                      >
                      <mat-label>Datum Einleitung des Verfahrens</mat-label>
                      <mat-error *ngIf="hasError(erstattungsverfahren.controls[i]?.controls?.datumVerfahren, 'matDatepickerMin')">
                        Ein Erstattungsverfahren darf nicht vor dem Antragseingang eingeleitet werden.
                      </mat-error>
                      <mat-error *ngIf="hasError(erstattungsverfahren.controls[i]?.controls?.datumVerfahren, 'required')">
                        Es muss ein Verfahrensdatum eingegeben werden.
                      </mat-error>
                      <button mat-icon-button matSuffix
                        class="delete-date"
                        matTooltip="Formularfeld leeren"
                        [disabled]="erstattungsverfahren.controls[i].controls.datumVerfahren.disabled"
                        (click)="erstattungsverfahren.controls[i].controls.datumVerfahren.reset()"
                      ><i class="material-icons">clear</i></button>
                      <mat-datepicker-toggle
                        [for]="datumVerfahrenPicker"
                        matSuffix
                        matTooltip="Datum auswählen"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #datumVerfahrenPicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-icon-button
                      (click)="deleteFrom(erstattungsverfahren, i)"
                      color="warn"
                      [disabled]="this.entscheidung.getRawValue().erledigungsartGesamtantrag === 'VOLLSTAENDIGE_ABLEHNUNG'"
                      class="delete"
                    >
                      <i class="material-icons">delete_forever</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button mat-stroked-button
              class="no-margin"
              color="primary"
              [disabled]="!validators.erstattungsverfahren.canBeAdded()"
              (click)="addToFormArray(erstattungsverfahren, erstattungsverfahrenFormGroup, 'Erstattungsverfahren', false)"
              (mouseenter)="onFocus($event, 'erstattungsverfahren', 'defaultAntrag')"
              (focusin)="onFocus($event, 'erstattungsverfahren', 'defaultAntrag')"
            >
              <i class="material-icons">add</i> Erstattungsverfahren hinzufügen
            </button>
            <div class="form-spacer-large"></div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="formToggles.teilhabe"
      (opened)="formToggles.teilhabe = true"
      (closed)="formToggles.teilhabe = false"
      [disabled]="!form.getRawValue().leistungenTeilhabeArbeitsleben
        || !form.getRawValue().entscheidung.datumEntscheidungLTA
        || form.getRawValue().entscheidung.erledigungsartLTA === ''
        || form.getRawValue().entscheidung.erledigungsartLTA === 'VOLLSTAENDIGE_ABLEHNUNG'
        || form.getRawValue().entscheidung.erledigungsartLTA === 'SONSTIGE'
        || form.getRawValue().weiterleitung === 'NACH_14_1'
        || form.getRawValue().weiterleitung === 'NACH_14_3'
      "
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="form-title">Berufliche Teilhabe</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <button mat-stroked-button
            color="warn"
            (click)="confirmDelete(resetBeruflicheTeilhabe)"
          >
            Eingaben entfernen
          </button>
          <div class="form-info-box" *ngIf="formToggles.teilhabe && currentInfoText !== '' && currentInfoSection === 'teilhabe'" [innerHTML]="currentInfoText"></div>
        </div>
        <div fxFlex="66%" fxLayout="column" *ngIf="formToggles.teilhabe">
          <mat-checkbox
            class="check-block"
            color="primary"
            formControlName="beschaeftigungNachLeistungsende"
            (change)="onChangeBeschaeftigungNachLeistungsende($event)"
            (mouseenter)="onFocus($event, 'teilhabe', 'beschaeftigungNachLeistungsende')"
            (focusin)="onFocus($event, 'teilhabe', 'beschaeftigungNachLeistungsende')"
          >
            Sozialversicherungspflichtige Beschäftigung 6 Monate nach Leistungsende
          </mat-checkbox>

          <div class="form-spacer-large"></div>

          <mat-form-field appearance="fill" ngClass="full-width">
            <input matInput
              [matDatepicker]="datumLTAMassnahmeEndePicker"
              [min]="form.getRawValue().erstellDatum"
              placeholder="TT.MM.JJJJ"
              id="datumLTAMassnahmeEnde"
              formControlName="datumLTAMassnahmeEnde"
            >
            <mat-label>LTA-Maßnahme-Ende</mat-label>
            <mat-error>Es muss ein Datum eingegeben werden.</mat-error>
            <button mat-icon-button matSuffix
              class="delete-date"
              matTooltip="Formularfeld leeren"
              [disabled]="form.controls.datumLTAMassnahmeEnde.disabled"
              (click)="form.controls.datumLTAMassnahmeEnde.reset()"
            ><i class="material-icons">clear</i></button>
            <mat-datepicker-toggle matSuffix [for]="datumLTAMassnahmeEndePicker" matTooltip="Datum auswählen"></mat-datepicker-toggle>
            <mat-datepicker #datumLTAMassnahmeEndePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>

<mat-card class="button-card">
  <mat-card-content>
    <button mat-stroked-button
      color="primary"
      (click)="navigateBack()"
    >
      <mat-icon class="material-icons">chevron_left</mat-icon>
      Zurück zur Liste der Anträge
    </button>
    <button mat-stroked-button
      color="primary"
      class="loading-button"
      [disabled]="!filter.id"
      (click)="download(filter.id, 'Antrag.pdf')"
    >
      <mat-spinner *ngIf="downloading$ | async" mode="indeterminate" diameter="15"></mat-spinner>
      PDF-Export
    </button>
    <button mat-stroked-button
      color="warn"
      [disabled]="!filter.id || !!currentIntegrationPlan.thvbID"
      (click)="onDelete()"
    >
      Löschen
    </button>
    <button mat-raised-button
      color="primary"
      [disabled]="disableButtonSave()"
      (click)="onSave()"
    >
      Speichern
    </button>
  </mat-card-content>
</mat-card>
