import { Injectable, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/components/custom-validators';

@Injectable()
export class AntragValidators implements OnInit {
  form: FormGroup;
  weiterleitung = 'WEITERLEITUNG_NACH_P14';

  constructor() { }

  ngOnInit() {
    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.budgetBeantragt,
      control => !!control[0].value,
      Validators.required
    );

    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.antragWeitergeleitet,
      control => !!control[0].value,
      Validators.required
    );

    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.antragWeitergeleitetP14A3,
      control => !!control[0].value,
      Validators.required
    );
  }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  valueOfWeitergeleitetChanges(weitergeleitet: boolean): void {
    if (weitergeleitet) {
      this.form.patchValue({
        entscheidung: {
          datumEntscheidungGesamtantrag: this.form.getRawValue().datumFeststellungZustaendigkeit,
          erledigungsartGesamtantrag: this.weiterleitung
        }
      });
    } else {
      this.entscheidung.patchValue({ erledigungsartGesamtantrag: '' });
      this.entscheidung.controls.datumEntscheidungGesamtantrag.reset();
    }
  }

  // Wenn datumFeststellungZustaendigkeit ausgefüllt ist, muss mindestens eine Leistungsgruppe ausgewählt sein
  atLeastOneLeistungsgruppeSelected(): boolean {
    return this.form.getRawValue().erstellDatum
      && !this.form.getRawValue().leistungenMedizinischeReha
      && !this.form.getRawValue().leistungenTeilhabeArbeitsleben
      && !this.form.getRawValue().leistungenTeilhabeBildung
      && !this.form.getRawValue().leistungenSozialeTeilhabe;
  }

  // Wird nur für (De-)Aktivierung der Formularbereiche benötigt.
  weiterleitungSelected(): boolean {
    return (
      this.form.getRawValue().antragWeitergeleitet
      || this.form.getRawValue().antragWeitergeleitetP14A3
      || this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === this.weiterleitung
    ) || !this.form.getRawValue().datumFeststellungZustaendigkeit;
  }

  // Disabled, wenn kein Feststellungsdatum und keine Leistungsgruppe ausgewählt ist
  disableAntragWeitergeleitet(): boolean {
    return (!this.form.getRawValue().leistungenMedizinischeReha
      && !this.form.getRawValue().leistungenTeilhabeArbeitsleben
      && !this.form.getRawValue().leistungenTeilhabeBildung
      && !this.form.getRawValue().leistungenSozialeTeilhabe
    )
      || !this.form.getRawValue().datumFeststellungZustaendigkeit;
  }

  get entscheidung(): FormGroup {
    return this.form.controls.entscheidung as FormGroup;
  }
}
