import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { IntegrationPlan, IntegrationPlanFilterOptions } from 'app/integration-plan/integration-plan';
import { MatDialogRef } from '@angular/material/dialog';
import { IntegrationPlanPopupFormComponent } from '../form/popup/integration-plan-popup-form.component';

export const integrationPlanActions = actionTypesFor('integrationPlan');

export const SET_SELECTED = '[IntegrationPlan] Set Selected';
export const SET_INTEGRATION_PLAN_FILTER = '[IntegrationPlan] SetFilter';
export const DELETE_INTEGRATION_PLAN = '[IntegrationPlan] Delete';
export const DELETE_INTEGRATION_PLAN_DONE = '[IntegrationPlan] Delete Done';
export const SAVE_INTEGRATION_PLAN = '[IntegrationPlan] Save';
export const SAVE_INTEGRATION_PLAN_DONE = '[IntegrationPlan] Save Done';

export class SetSelectedIntegrationPlan implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: IntegrationPlan) { }
}

export class SetIntegrationPlanFilter implements Action {
  readonly type = SET_INTEGRATION_PLAN_FILTER;
  constructor(public payload: IntegrationPlanFilterOptions) {}
}

export class DeleteIntegrationPlan implements Action {
  readonly type = DELETE_INTEGRATION_PLAN;
  constructor(public id: string, public reload: boolean) {}
}

export class DeleteIntegrationPlanDone implements Action {
  readonly type = DELETE_INTEGRATION_PLAN_DONE;
  constructor(public reload: boolean) {}
}

export class SaveIntegrationPlan implements Action {
  readonly type = SAVE_INTEGRATION_PLAN;
  constructor(public entity: any, public dialog: MatDialogRef<IntegrationPlanPopupFormComponent>) {}
}

export class SaveIntegrationPlanDone implements Action {
  readonly type = SAVE_INTEGRATION_PLAN_DONE;
  constructor(public entity: any, public dialog: MatDialogRef<IntegrationPlanPopupFormComponent>) {}
}
